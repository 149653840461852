import request from '@/utils/http.service';
import { memberApi } from '@/utils/api.config';

export function getAboutUs(data: any) {
    return request({
        url: memberApi.getteamsetting,
        method: 'POST',
        data
    }).then((results) => results)
}

export function getTongjiData(data: any) {
    return request({
        url: memberApi.gettongjidata,
        method: 'POST',
        data
    }).then((results) => results)
}

export function getUserFeedbackList(data: any) {
    return request({
        url: memberApi.getuserfeedbacklist,
        method: 'POST',
        data
    }).then((results) => results)
}

export function getUserDetail(data: any) {
    return request({
        url: memberApi.getuserdetail,
        method: 'POST',
        data
    }).then((results) => results)
}

export function getusertenderlist(data: any) {
    return request({
        url: memberApi.getusertenderlist,
        method: 'POST',
        data
    }).then((results) => results)
}

export function getusertenderdetail(data: any) {
    return request({
        url: memberApi.getusertenderdetail,
        method: 'POST',
        data
    }).then((results) => results)
}

export function getusertendernoticedetail(data: any) {
    return request({
        url: memberApi.getusertendernoticedetail,
        method: 'POST',
        data
    }).then((results) => results)
}

export function getUserWorkerOrSupplierDetail(data: any) {
    return request({
        url: memberApi.getuserworkerorsupplierdetail,
        method: 'POST',
        data
    }).then((results) => results)
}

export function checkLogin(data: any) {
    return request({
        url: memberApi.checklogin,
        method: 'POST',
        data
    }).then((results) => results)
}

export function getDicSimpleList(data: any) {
    return request({
        url: memberApi.getsimplelist,
        method: 'POST',
        data
    }).then((results) => results)
}

export function feedbackCommit(data: any) {
    return request({
        url: memberApi.feedbackcommit,
        method: 'POST',
        data
    }).then(() => true)
}
export function supplierDataCommit(data: any) {
    return request({
        url: memberApi.supplierdatacommit,
        method: 'POST',
        data
    }).then(() => true)
}
export function workerDataCommit(data: any) {
    return request({
        url: memberApi.workerdatacommit,
        method: 'POST',
        data
    }).then(() => true)
}

export function sendVerifyCode(data: any) {
    return request({
        url: memberApi.sendverifycode,
        method: 'POST',
        data
    }).then(() => true)
}