
import { skipRouter } from "@/router";
import {
  IonPage,
  IonContent,
  IonInput,
  IonCheckbox,
  onIonViewDidEnter,
  onIonViewWillEnter,
} from "@ionic/vue";
import { ref, reactive, computed, onMounted } from "vue";
import { openTips } from "@/utils/alert";
import { sendVerifyCode, checkLogin } from "@/service/member";
import { userinfo } from "@/utils/usermodule";
import { local } from "@/utils/storage.service";

export default {
  name: "Login",
  components: { IonContent, IonPage, IonInput, IonCheckbox },
  setup() {
    const isRead = ref(true);
    const userobj: any = ref([]);
    const usermobile: any = ref("");
    const usercode: any = ref("");
    const loginuser = local.get("loginuser");
    const codeTime = ref(0);

    //发验证码
    const getSendverifyCode = async () => {
      local.clear();
      if (usermobile.value === "") {
        openTips("手机账号不能为空!");
        return false;
      }
      codeTime.value = 120;
      const timeout = setInterval(() => {
        codeTime.value -= 1;
        if (codeTime.value === 0) clearInterval(timeout);
      }, 1000);

      const result = await sendVerifyCode({
        mobile: usermobile.value,
      });
      return true;
    };
    //登录注册
    const checkLoginVoid = async () => {
      if (usermobile.value === "") {
        openTips("手机账号不能为空!");
        return;
      }
      if (usercode.value === "") {
        openTips("验证码不能为空!");
        return;
      }
      if (!isRead.value) {
        openTips("请同意使用条款和隐私协议!");
        return;
      }
      const result = await checkLogin({
        mobile: usermobile.value,
        verifycode: usercode.value,
        // mobile: "13810720211",
        // verifycode: "1234",
      });

      userobj.value = result;
      userinfo.userid = userobj.value.userid;
      userinfo.username = userobj.value.username;
      userinfo.mobile = userobj.value.mobile;
      userinfo.usertype = userobj.value.usertype;
      userinfo.islogin = "1";
      userinfo.workerid = "";
      userinfo.supplierid = "";
      local.set("loginuser", userinfo);

      if (userinfo.usertype == "0") {
        skipRouter("/login/selected-identity");
      } else {
        skipRouter("/tabs/home");
      }
    };

    const countDown = () => {
      getSendverifyCode();
    };

    return {
      isRead,
      skipRouter,
      usermobile,
      usercode,
      getSendverifyCode,
      checkLoginVoid,
      codeTime,
      countDown,
    };
  },
};
